import React from "react";
import { IconButton, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from '@chakra-ui/icons'
import {useT} from "./t";

const SearchWidget = ({ query, handleSearch, handleSearchEnter, clearSearch } ) => {

    const {t} = useT();

    return <InputGroup mb={4}>
        <Input value={query} onChange={handleSearch} onKeyPress={handleSearchEnter} placeholder={t("searchPlaceholder","Otsi...")}
               borderRadius="full"/>
        <InputRightElement>
            {query ? <IconButton onClick={clearSearch} variant="ghost" color="gray.400" aria-label="Delete"
                                 icon={<CloseIcon/>} borderRadius="full"/> :
                <IconButton variant="ghost" aria-label="Search" icon={<SearchIcon/>} borderRadius="full"/>}
        </InputRightElement>
    </InputGroup>
}

export default SearchWidget

import React, {useEffect} from 'react';
import {
  useRecoilState,
} from 'recoil';
import {AppStore} from "./store";
import {useHistory, useLocation} from "react-router-dom";

const Logout = () => {

  const [appState, setAppState] = useRecoilState(AppStore)
  const history = useHistory()

  useEffect(() => {
    setAppState({ ...appState, token: null })
    history.replace({ pathname: '/' })
  })


  return null

}

export default Logout

import React, { useTransition } from 'react';

import {
  useRecoilState,
  useRecoilValue, atom,
} from 'recoil';
import {Badge, Button, Flex, Heading} from "@chakra-ui/react";
import Table from "./table";
import { ListQueryState, ListResourceQuery } from "./store";
import { useHistory, Link } from "react-router-dom";
import SearchWidget from "./SearchWidget";
import {useList} from "./useList";
import {useSearch} from "./useSearch";
import T, {useT} from "./t";

const clearSearch = () => {}

const SearchParamsStore = atom({
  key: 'SearchParamsStoreUser',
  default: "",
});

const statusColors = {
  'ENABLED': 'green',
  'DISABLED': 'red'
}

const statusTexts = {
  'ENABLED': 'SEES',
  'DISABLED': 'VÄLJAS'
}

const UserList = () => {

  const { t } = useT();

  let history = useHistory();
  const { list: users, handleListQuery } = useList('/user/list')
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/user/list', handleListQuery)

  const onRowClick = ({ id }) => {
    history.push(`/users/${id}`)
  }

  console.log('UserList', ' users: ');

  return <Flex flexDirection="column" flex="1" pr={4}>
    <Heading mb={2}><T k="usersHeading">Kasutajad</T></Heading>
    <SearchWidget query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                  clearSearch={clearSearch}/>

    <Flex justifyContent="flex-end" mb={4}>
      <Button as={Link} to="/users/new" colorScheme="blue" variant="outline">
        <T k="addNewUserBtnTxt">Lisa uus kasutaja</T>
      </Button>
    </Flex>

    <Table
      columns={[{
          Header: t(["statusColumn", "Olek"]),
          id: "status",
          accessor: (r) => {
            return <Badge fontSize="0.8em" variant="subtle" colorScheme={statusColors[r.status]}>{t([r.status, statusTexts[r.status]])}</Badge>
          }
        },{
          Header: t(["nameColumn", "Nimi"]),
          accessor: "name"
        },{
          Header: t(["emailColumn", "E-post"]),
          accessor: "email"
        },{
          Header: t(["providerNameColumn", "Kuulub gruppile"]),
          accessor: "provider_name",
        },{
          Header: t(["mayManageColumn", "Võib hallata"]),
          id: "service_provider_ids",
          accessor: (r) => {
            const providers = (r.providers || []).map((s) => s.name)
            return providers.length ? providers.join(', '): '--';
          }
        }
      ]}
      data={users.data}
      totalHits={users.totalHits}
      page={users.page}
      lastPage={users.totalHits >= 0 ? users.lastPage + 1: 0}
      pageSize={users.pageSize}
      sortBy={""}
      sortDesc={false}
      fetchData={(p) => {
        handleListQuery(p)
      }}
      onRowClick={onRowClick}
    />
  </Flex>
}

export default UserList;

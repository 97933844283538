import React from 'react';
import {
  Box,
  Badge,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Radio,
  Stack, Select,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';
import {useResource} from "./useResource";
import {format} from "date-fns";
import T, {useT} from "./t";

const statusColors = {
  'ACCEPT': 'green',
  'DENY': 'orange',
  'ERROR': 'red'
}

const statusTexts = {
  'ACCEPT': 'LUBATUD',
  'DENY': 'KEELATUD',
  'ERROR': 'VIGA'
}

const LogDetails = () => {

  const { t } = useT();

  const { id } = useParams();
  const { state, handleChanges } = useResource(`/accesslog/${id}`)
  const log = Object.assign({}, state.data, state.changes);

  const errors = {}

  const kp = log.created_at ? format(
      new Date(log.created_at),
      t('dd.MM.yyyy \'kell\' kk:mm:ss', { keySeparator: '*', nsSeparator: '%' })
    ) : '--';


  console.log('LogDetails')

  return <Box flexDirection="column" flex="1">
    <Box p={4} >

      <Flex mt={3}>
        <FormControl flex={1}>
          <FormLabel>{t(["codeField", "Kood"])}</FormLabel>
          <Text>{log.code || '--'}</Text>
        </FormControl>
        <FormControl flex={1}>
          <FormLabel as="legend">{t(["resultField", "Tulemus"])}</FormLabel>
          <Badge fontSize="0.8em" variant="subtle" colorScheme={statusColors[log.result]}>{t(log.result)}</Badge>
        </FormControl>
      </Flex>

      <Flex mt={3}>
        <FormControl flex={1}>
          <FormLabel>{t(["zoneField", "Tsoon"])}</FormLabel>
          <Text>{log.site_name || '--'}</Text>
        </FormControl>
        <FormControl flex={1}>
          <FormLabel as="legend">{t(["lockField", "Lukk"])}</FormLabel>
          <Text>{log.controller_name || '--'}</Text>
        </FormControl>
      </Flex>
      <FormControl mt={3}>
        <FormLabel htmlFor="type">{t(["timeField", "Aeg"])}</FormLabel>
        <Text>{kp}</Text>
      </FormControl>
      <FormControl mt={3}>
        <FormLabel htmlFor="type">{t(["dataField", "Andmed"])}</FormLabel>
        <Table variant="simple">
          <Tbody>
            {Object.entries((log.data && log.data[0]) ? log.data[0] : (log.data || [])).map((r) => <Tr key={r[0]}>
              <Td fontWeight="bold">{r[0]}</Td>
              <Td>{JSON.stringify(r[1])}</Td>
            </Tr>)}
          </Tbody>
        </Table>
      </FormControl>
    </Box>
  </Box>
}

export default LogDetails;

import React, { useTransition } from 'react';

import {
  useRecoilState,
  useRecoilValue, atom,
} from 'recoil';
import {Badge, Button, Flex, Heading} from "@chakra-ui/react";
import Table from "./table";
import { ListQueryState, ListResourceQuery } from "./store";
import { useHistory, Link } from "react-router-dom";
import SearchWidget from "./SearchWidget";
import {useList} from "./useList";
import {useSearch} from "./useSearch";
import T, {useT} from "./t";


const SearchParamsStore = atom({
  key: 'SearchParamsStoreCode',
  default: "",
});

const statusColors = {
  'ENABLED': 'green',
  'SINGLE_USE': 'purple',
  'DISABLED': 'red'
}

const statusTexts = {
  'ENABLED': 'SEES',
  'SINGLE_USE': 'ÜHEKORDNE',
  'DISABLED': 'VÄLJAS'
}

const types = {
  'MSISDN': 'Mobiil',
  'PIN' : 'Pin',
  'RFID': 'RFID'
}


const CodeList = () => {

  const { t } = useT();

  let history = useHistory();
  const { list: codes, handleListQuery } = useList('/access/list')
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/access/list', handleListQuery)


  const onRowClick = ({ id }) => {
    history.push(`/codes/${id}`)
  }

  console.log('CodeList', ' codes: ');

  return <Flex flexDirection="column" flex="1" pr={4}>
    <Heading mb={2}><T k="codesHeading">Koodid</T></Heading>
    <SearchWidget query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                  clearSearch={clearSearch}/>

    <Flex justifyContent="flex-end" mb={4}>
    <Button as={Link} to="/codes/new" colorScheme="blue" variant="outline">
      <T k="addNewCodeBtnTxt">Lisa uus kood</T>
    </Button>
    </Flex>

    <Table
      columns={[
        {
          Header: t(["statusColumn", "Olek"]),
          id: "status",
          accessor: (r) => {
            return <Badge fontSize="0.8em" variant="subtle" colorScheme={statusColors[r.status]}>{t([r.status, statusTexts[r.status]])}</Badge>
          }
        },{
          Header: t(["typeColumn", "Tüüp"]),
          id: "type",
          accessor: (r) => {
            return t([r.type, types[r.type]])
          }
        },{
          Header: t(["codeColumn", "Kood"]),
          accessor: "key"
        },{
          Header: t(["commentColumn", "Komentaar"]),
          accessor: "comment"
        },{
          Header: t(["zoneColumn", "Tsoon"]),
          id: "zone",
          accessor: (r) => {
            //return format(new Date(r.requested_at), 'dd.MM.yyyy \'at\' kk:mm');

            const sites = (r.sites || []).map((s) => s.name)

            //return format(new Date(r.requested_at), 'dd.MM.yyyy \'at\' kk:mm');
            return sites.length ? sites.join(', '): '--';
          }
        },{
          Header: t(["locksColumn", "Lukkud"]),
          id: "locks",
          accessor: (r) => {

            const locks = (r.sites || []).reduce((d,v) => {
              return d.concat((v.controllers || []).map((c) => c.name))
            }, [])

            return locks.length ? locks.join(', '): '--';
          }
        }

      ]}
      data={codes.data}
      totalHits={codes.totalHits}
      page={codes.page}
      lastPage={codes.totalHits >= 0 ? codes.lastPage + 1: 0}
      pageSize={codes.pageSize}
      sortBy={""}
      sortDesc={false}
      fetchData={(p) => {
        handleListQuery(p)
      }
      }
      onRowClick={onRowClick}
    />
  </Flex>
}

export default CodeList;

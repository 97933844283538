import * as React from 'react';
import {useCallback} from "react";

import { useToast } from "@chakra-ui/react"

import {atomFamily, useRecoilState, useRecoilValue} from "recoil";
import {
  AppStore,
  ListResourceQueryResult,
} from "./store";
import {useHistory} from "react-router-dom";

const SearchParamsStore = atomFamily({
  key: 'SearchParamsStore',
  default: "",
});

export function useSearch(resourcePath, listQueryHandler) {

  const list = useRecoilValue(ListResourceQueryResult(resourcePath))
  const [query, setQuery] = useRecoilState(SearchParamsStore(resourcePath));

  let history = useHistory();
  const { location } = history;
  const { search } = location;
  const url_params = new URLSearchParams(search);

  const handleSearch = useCallback((ev) => {
    const target = ev.target;
    url_params.set('q', target.value)
    const loc = Object.assign({}, location, {
      search: url_params.toString()
    })
    history.replace(loc)
    setQuery(target.value)

  }, [resourcePath])

  const handleSearchEnter = (ev) => {
    if(ev && ev.key && ev.key === 'Enter') {
      const p = { page: 0, pageSize: list.pageSize, sortBy: list.sortBy, sortDesc: list.sortDesc, search: query, filters: {} }
      listQueryHandler(p);
    }
  }

  const clearSearch = useCallback(() => {
    setQuery("")
    const p = { page: 0, pageSize: list.pageSize, sortBy: list.sortBy, sortDesc: list.sortDesc, search: "", filters: {} }
    listQueryHandler(p);
  },[resourcePath])

  return { query, handleSearch, handleSearchEnter, clearSearch }
}

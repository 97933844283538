import React from 'react'
import styled from "@emotion/styled";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Select
} from '@chakra-ui/react'
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp
} from 'react-feather'
import {useMediaQuery} from 'react-responsive'
import {usePagination, useSortBy, useTable} from 'react-table'
import {useHistory} from "react-router";
import { useT } from "./t";

const Table = React.memo(({columns, data, fetchData, onRowClick, page: controlledPage, lastPage, pageSize: controlledPageSize, sortBy: controlledSortBy, sortDesc, localSort}) => {

  const { t } = useT();

  const history = useHistory();
  const {location} = history;
  const {search} = location;
  const url_params = new URLSearchParams(search);
  const url_params_orig = new URLSearchParams(search);

  const tableColumns = React.useMemo(() => columns, [columns])

  const isTabletOrMobile = useMediaQuery({query: '(max-width: 40em)'})

  const initialSortBy = (controlledSortBy ? [{
    id: controlledSortBy,
    desc: sortDesc
  }] : [])

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize, sortBy}
  } = useTable(
    {
      columns: tableColumns,
      manualPagination: true,
      autoResetPage: false,
      pageCount: (lastPage || 1),
      data,
      initialState: { pageIndex: controlledPage, pageSize: (controlledPageSize || 10000), sortBy: initialSortBy },
      disableMultiSort: true,
      manualSortBy: (localSort ? false : true)
    },
    useSortBy,
    usePagination
  )

  console.log('%table Render', " lastPage: ", lastPage, " pageSize: ",pageSize, " controlledPageSize: ",controlledPageSize)

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    // Clean up sortBy

    let sortId = ''
    let sortDesc = false;
    let ordering = ''

    console.log('fetchData', 'pageIndex:', pageIndex, 'pageSize:', pageSize, 'pageCount:', pageCount, 'sortBy:', sortBy, initialSortBy )

    if(sortBy && sortBy[0] && sortBy[0].id) {
      sortId = sortBy[0].id;
      sortDesc = sortBy[0].desc;
      ordering = (sortDesc ? '-' : '') + sortId;
    }

    url_params.set('o', ordering);

    if(pageIndex !== undefined) {
      url_params.set('s', pageSize);
      url_params.set('p', pageIndex);
    }
    const loc = Object.assign({}, location, {
      search: url_params.toString()
    })

    if(loc.search != url_params_orig.toString()) {
      history.replace(loc)
    }

    if (fetchData) {
      fetchData({page: pageIndex, pageSize, sortBy: sortId, sortDesc })
    }
  }, [pageIndex, pageSize, sortBy])

  return <Flex
    bg="white"
    width="auto"
    maxWidth="100%"
    rounded="0"
    borderWidth="0px"
    flexDirection="column"
    flex="1"
  >
    {/* Table Header */}
    <Flex>
      {headerGroups.map((headerGroup) => (
        <Flex
          key={headerGroup.id}
          flex={1}
          flexDirection="row"
          borderBottom="2px solid #666"
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <TableCell
              p={4}
              key={column.id}
              {...column.getHeaderProps()}
              justifyContent="space-between"
              borderColor="transparent"
              {...column.getSortByToggleProps()}
            >
              <Text fontWeight="bold">{column.render('Header')}</Text>
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <ChevronDown size={20}/>
                ) : (
                  <ChevronUp size={20}/>
                )
              ) : (
                ''
              )}
            </TableCell>
          ))}
        </Flex>
      ))}
    </Flex>
    {/* Table Body */}
    <Flex flexDirection="column" flex={1} flexBasis="auto">
      {page.map(
        (row, key) =>
          prepareRow(row) || (
            <TableRow
              onClick={() => onRowClick && onRowClick(row.original)}
              key={key}
              flexDirection="row"
              {...row.getRowProps()}
              data-testid="table-row"
            >{row.cells.map((cell) => {
              return (
                <TableCell
                  key={cell.row.index}
                  justifyContent="flex-start"
                  p={4}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </TableCell>
              )
            })}
            </TableRow>
          )
      )}
    </Flex>
    {/* Footer (sums and such) */}
    {/* Paging */}
    {controlledPageSize &&
    <Flex
      justifyContent="space-between"
      flexDirection="row"
      p={5}
    >
      <Flex flexDirection="row">
        <TableIconButton
          mr={2}
          onClick={() => gotoPage(0)}
          isDisabled={!canPreviousPage}
          icon={<ChevronsLeft size={20}/>}
        />
        <TableIconButton
          mr={2}
          isDisabled={!canPreviousPage}
          onClick={() => previousPage()}
          icon={<ChevronLeft size={20}/>}
        />
      </Flex>
      <Flex justifyContent="center" alignItems="center" whiteSpace="nowrap">
        <Text mr={4}>
          {t(["tableFooterPage", "Lehekülg"])}{' '}
          <strong>
            {pageIndex + 1} / {pageOptions.length}
          </strong>{' '}
        </Text>
        {!isTabletOrMobile && (
          <Select
            variant="unstyled"
            size="md"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[1,2,3, 5, 10, 50, 100, 250].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t(["tableFooterShowRows", "Näita"])} {pageSize}
              </option>
            ))}
          </Select>
        )}
      </Flex>
      <Flex flexDirection="row">
        <TableIconButton
          ml={2}
          isDisabled={!canNextPage}
          onClick={() => nextPage()}
          icon={<ChevronRight size={20}/>}
        />
        <TableIconButton
          ml={2}
          onClick={() => gotoPage(pageCount ? pageCount - 1 : 1)}
          isDisabled={!canNextPage}
          icon={<ChevronsRight size={20}/>}
        />
      </Flex>
    </Flex>
    }
  </Flex>
})

const TableRow = styled(Flex)`
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.01);
  }
`;

const TableCell = styled(Box)`
  flex: 1;
  display: flex;
  min-width: 150px;
  align-items: center;
  border-bottom-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableIconButton = ({
                           icon,
                           onClick,
                           isDisabled,
                           children,
                           ...rest
                         }) => {
  return (
    <IconButton
      size="sm"
      {...rest}
      icon={icon}
      borderWidth={1}
      onClick={onClick}
      isDisabled={isDisabled}
      aria-label="Table Icon button"
    >
      {children}
    </IconButton>
  );
};

const TableHeader = (props) => (
  <Flex>
    TH
  </Flex>
)
Table.Header = TableHeader

export default Table;

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function T({ k, children }) {
    const { t } = useTranslation('accesscontrol-admin', { useSuspense: false });
    return <>{t([k, children])}</>
}

export function useT() {
    return useTranslation('accesscontrol-admin', { useSuspense: false });
}
import React from "react";
import {
  Box, Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger, Text
} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import T from "./t";

function isEmpty(obj) {
  for(const i in obj) return false;
  return true;
}

const trashIcon = <FontAwesomeIcon icon={faTrashAlt} />;

const ButtonActionRow = ({ state, onResetForm, onDelete }) => {
  return <Flex justifyContent="flex-end" alignItems="center" mt={4}>
    <Box flex="1" >
      <Popover>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <IconButton
                variant="ghost"
                colorScheme="red"
                aria-label="Delete"
                icon={trashIcon}
                disabled={isOpen || isEmpty(state.data)}
              />
            </PopoverTrigger>
            <PopoverContent zIndex={4}>
              <PopoverArrow />
              <PopoverBody as={Flex} flexDirection="column">
                <Flex justifyContent="center" mt={3}><T k="deleteConfirmationTxt">Oled kindel, et soovid kustutada?</T></Flex>
                <Flex justifyContent="center" alignItems="center" mt={4}>
                  <Button  type="button" variant="link" onClick={(ev) => { ev.preventDefault(); onClose(ev) }}><T k="closeLinkTxt">sulge</T></Button>
                  <Text mx={4}><T k="orTxt">või</T></Text>
                  <Button
                    type="button"
                    colorScheme="red"
                    onClick={(ev) => { ev.preventDefault(); onDelete(ev) }}
                  >
                    <T k="btnDeleteTxt">Kustuta</T>
                  </Button>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
    <Button variant="link" type="button" isDisabled={isEmpty(state.changes)} onClick={(ev) => { ev.preventDefault(); onResetForm(ev) }}><T k="resetLinkTxt">lähtesta</T></Button>
    <Text mx={4}><T k="orTxt">või</T></Text>
    <Button
      colorScheme="teal"
      type="submit"
      isDisabled={isEmpty(state.changes)}
    >
      <T k="saveBtnTxt">Salvesta</T>
    </Button>
  </Flex>
};

export default ButtonActionRow;

import React, {useCallback, useEffect} from 'react';
import {
  Box,
  Badge,
  Checkbox,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Radio,
  Stack, Select,
  Text, FormHelperText
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';
import ChangesRow from "./changesRow";
import ButtonActionRow from "./buttonActionRow";
import {useResource} from "./useResource";
import MultiSelect from "./MultiSelect";
import {atom, selector, useRecoilState, useRecoilValue} from "recoil";
import T, {useT} from "./t";
import { Trans } from 'react-i18next'

const statusColors = {
  'ENABLED': 'green',
//  'SINGLE_USE': 'purple',
  'DISABLED': 'red'
}

const statusTexts = {
  'ENABLED': 'SEES',
  'SINGLE_USE': 'ÜHEKOrDNE',
  'DISABLED': 'VÄLJAS'
}


const types = {
  'MSISDN': 'Mobiil',
  'PIN' : 'Pin',
  'RFID': 'RFID'
}

const siteConfStore = atom({
  key: 'siteConfStore',
  default: null,
});

const CodeDetails = () => {

  const { t } = useT();

  const { id } = useParams();
  const history = useHistory();
  const { state, handleChanges, handleReset, handleSave, handleDelete } = useResource(`/access/${id}`)
  const code = Object.assign({}, state.data, state.changes);
  const sites = (state.related.sites || {});
  const providers = (state.related.providers || {});

  const [selectedSites, setSelectedSites] = useRecoilState(siteConfStore);
  const locks = (selectedSites || []).reduce((d, v) => {
    if(!sites[String(v)]) { return d; }
    if(!(sites[String(v)]).controllers) { return d; }
    return d.concat((sites[String(v)]).controllers)
  }, [])

  /*
  useEffect(() => {
    console.log('onselectedSites', selectedSites)
    handleChanges('sites')((selectedSites || []).map((s) => Number(s)))
  }, [selectedSites])
   */

  useEffect(() => {
    /*
    console.log('ZZZITEZ', (code.sites || []).reduce((d,v) => {
      if(sites[String(v)]) {
        d = d.concat([String(v)])
      }
      return d
    }, []));
     */
    //setSelectedSites((code.sites || []).map((s) => String(s)))
    setSelectedSites((code.sites || []).reduce((d,v) => {
      if(sites[String(v)]) {
        d = d.concat([String(v)])
      }
      return d
    }, []))
  }, [code.sites])

  const onLockChange = useCallback((lock_id) => {
    return (ev) => {

      let lock_ids = (code.controllers || []).concat();
      if(ev.target.checked) {
        // Add to array
        lock_ids.push(Number(lock_id));
      } else {
        // Remove from array
        lock_ids = lock_ids.filter((i) => (i != Number(lock_id)));
      }
      handleChanges('controllers')(lock_ids);
    }
  }, [code.controllers])

  const errors = {}

  console.log('CodeDetails', 'data:', state.data, 'changes:', state.changes, 'code:', code, 'locks: ', locks)

  return <Box flexDirection="column" flex="1">
    <Box as={"form"} onSubmit={(ev) => { ev.preventDefault(); handleSave(); }} p={4} >
      <FormControl>
        <FormLabel>{t(["idField", "ID"])}</FormLabel>
        <Text>{code.id || '--'}</Text>
      </FormControl>
      <FormControl as="fieldset" isRequired mt={3}>
        <FormLabel as="legend">{t(["statusField", "Olek"])}</FormLabel>
        <Stack
          isInline
          spacing={4}>
          {Object.keys(statusColors).map(k => (
            <Radio key={k}
                   name="status"
                   isChecked={String(code.status) === String(k)}
                   onChange={handleChanges()}
                   value={k}
            >
              <Badge fontSize="0.8em" variant="subtle" colorScheme={statusColors[k]}>{t([k, statusTexts[k]])}</Badge>
            </Radio>
          ))}
        </Stack>
        <FormHelperText><Trans t={t} i18nKey="statusHelpTxt">Koodi saab kasutada ainult siis kui olek on <Badge fontSize="0.8em" variant="subtle" colorScheme="green">SEES</Badge></Trans></FormHelperText>
      </FormControl>
      <ChangesRow updatedAt={code.updated_at} createdAt={code.created_at} />
      <FormControl as="fieldset" isRequired mt={3}>
        <FormLabel htmlFor="type">{t(["providerNameField", "Kuulub grupile"])}</FormLabel>
        <MultiSelect
          name="service_provider_id"
          options={Object.keys(providers).map((s) => (String(s)))}
          getOptionLabel={(o) => { return (providers[String(o)] || {}).name }}
          getOptionValue={(o) => { return String(o)}}
          value={code.service_provider_id ? [String(code.service_provider_id)]: []}
          onChange={(sp) => {
            handleChanges('service_provider_id')(sp);
          }}
          placeholder={t(["providerPlaceholderTxt","Vali grupp kellele kood kuulub"])}
        />
        <FormHelperText><T k="providerHelpTxt">Grupp määrab kellele kood kuulub ja see omakorda määrab kellel on õigus koodi muuta</T></FormHelperText>
      </FormControl>
      <FormControl as="fieldset" isRequired mt={3}>
        <FormLabel htmlFor="type">{t(["typeField", "Tüüp"])}</FormLabel>
        <Stack
          isInline
          spacing={4}>
          {Object.keys(types).map(k => (
            <Radio key={k}
                   name="type"
                   isChecked={String(code.type) === String(k)}
                   onChange={handleChanges()}
                   value={k}
            >
              {t([k,types[k]])}
            </Radio>
          ))}
        </Stack>
        <FormHelperText><Trans t={t} i18nKey="typeHelpTxt">Tüüp määrab kuidas lukku saab avada:
          <ul>
            <li><strong>Mobiil</strong> — luku saab avada mobiiliga, helistades numbrile mis on kuvatud tahvlile
              või sisestades klaviatuurile mobiilinumbri viimased 4 numbrit</li>
            <li><strong>PIN</strong> — klient saab avada luku ainult PIN koodiga</li>
            <li><strong>RFID</strong> — klient saab avada luku ainult RFID paelaga/kaardiga</li>
          </ul></Trans>
        </FormHelperText>
      </FormControl>

      <FormControl isRequired mt={3}>
        <FormLabel htmlFor="sites">{t(["zoneField", "Tsoon"])}</FormLabel>
        <MultiSelect
          isMulti
          name="sites"
          options={Object.keys(sites).map((s) => (String(s)))}
          getOptionLabel={(o) => { return (sites[String(o)] || {}).name }}
          getOptionValue={(o) => { return String(o)}}
          value={selectedSites}
          onChange={(sz) => {
            const sites = (sz || []).map((s) => Number(s))
            handleChanges('sites')(sites)
            }
          }
          placeholder={t(["zonePlaceholderTxt", "Vali tsoon kus kood kehtib"])}
           />
      </FormControl>

      <FormControl isRequired mt={3}>
        <FormLabel htmlFor="sites">{t(["locksField", "Lukud"])}</FormLabel>
        <Stack direction="row" spacing={4} wrap="wrap">
          {locks && locks.map(l => <Checkbox key={l.id}
                                             value={l.id}
                                             isChecked={(code.controllers ||[] ).indexOf(l.id) > -1}
                                             onChange={onLockChange(l.id)}>
            {l.name}</Checkbox>)}
        </Stack>
        <FormHelperText><T k="locksHelpTxt">Kui oled valinud tsooni saad siit valida mis lukud kood avab</T></FormHelperText>
      </FormControl>

      <FormControl isRequired mt={3}>
        <FormLabel htmlFor="name">{t(["codeField", "Kood"])}</FormLabel>
        <Input
          type="text"
          name="key"
          value={code.key || ""}
          onChange={handleChanges()}
          placeholder={t(["codePlaceholderTxt","Sisesta ligipääsukood vastavalt allolevale juhendile"])}
        />
        <FormHelperText><Trans t={t} i18nKey="codeHelpTxt">Juhul kui tüübiks on:
          <ul>
            <li><strong>Mobiil</strong> — kood peab olema mobiilinumber koos riigikoodiga nt; +3721234567</li>
            <li><strong>PIN</strong> — neljakohaline number</li>
            <li><strong>RFID</strong> — kaheksakohaline kood nt. E27694A2</li>
          </ul></Trans>
        </FormHelperText>
        <FormErrorMessage>
          {errors.key && errors.key.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired mt={3}>
        <FormLabel htmlFor="valid_from">{t(["validFromField", "Kehtiv alates"])}</FormLabel>
        <DatePicker name="valid_from"
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    showTimeSelect
                    selected={code.valid_from ? new Date(code.valid_from) : null }
                    onChange={handleChanges('valid_from')}
                    placeholderText="When the Code is valid from" />
      </FormControl>
      <FormControl mt={3}>
        <FormLabel htmlFor="valid_to">{t(["validToField", "Kehtiv kuni"])}</FormLabel>
        <DatePicker name="valid_to"
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    showTimeSelect
                    selected={code.valid_to ? new Date(code.valid_to) : null }
                    onChange={handleChanges('valid_to')}
                    placeholderText={t(["validToPlaceholderTxt","Jäta tühjaks kui soovid, et kood kehtiks igavesti"])} />
      </FormControl>
      <FormControl mt={3}>
        <FormLabel htmlFor="valid_to">{t(["commentField", "Kommentaar"])}</FormLabel>
        <Textarea
          name="comment"
          value={code.comment}
          onChange={handleChanges()}
          placeholder={t(["commentPlaceholderTxt","Lisa siia kirjelduse nii et tead hiljem kelle kood see on"])}
        />
      </FormControl>

      <ButtonActionRow onDelete={(ev) => { const resp = handleDelete(ev); history.replace({ pathname: '/codes'  }); return resp }} onResetForm={handleReset} state={state} />
    </Box>
  </Box>
}

export default CodeDetails;

import React from 'react';
import {
  Box,
  Badge,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Radio,
  Stack, Select,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption, FormHelperText,
} from "@chakra-ui/react";
import {useHistory, useParams} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';
import ChangesRow from "./changesRow";
import ButtonActionRow from "./buttonActionRow";
import {useResource} from "./useResource";
import {format} from "date-fns";
import MultiSelect from "./MultiSelect";
import T, {useT} from "./t";
import { Trans } from 'react-i18next'

const statusColors = {
  'ENABLED': 'green',
  'DISABLED': 'red'
}

const statusTexts = {
  'ENABLED': 'SEES',
  'DISABLED': 'VÄLJAS'
}


const UserDetails = () => {

  const { t } = useT();

  const { id } = useParams();
  const history = useHistory();
  const { state, handleChanges, handleReset, handleSave, handleDelete } = useResource(`/user/${id}`)
  const user = Object.assign({}, state.data, state.changes);
  const providers = (state.related.providers || {});

  const errors = {}

  const kp = user.last_sign_in_at ? format(
    new Date(user.last_sign_in_at),
      t('dd.MM.yyyy \'kell\' kk:mm:ss', { keySeparator: '*', nsSeparator: '%' })
  ) : '--';


  console.log('UserDetails')

  return <Box flexDirection="column" flex="1">
    <Box as={"form"} onSubmit={(ev) => { ev.preventDefault(); handleSave(); }} p={4} >

      <FormControl>
        <FormLabel>{t(["idField", "ID"])}</FormLabel>
        <Text>{user.id || '--'}</Text>
      </FormControl>
      <FormControl as="fieldset" isRequired mt={3}>
        <FormLabel as="legend">{t(["statusField", "Olek"])}</FormLabel>
        <Stack
          isInline
          spacing={4}>
          {Object.keys(statusColors).map(k => (
            <Radio key={k}
                   name="status"
                   isChecked={String(user.status) === String(k)}
                   onChange={handleChanges()}
                   value={k}
            >
              <Badge fontSize="0.8em" variant="subtle" colorScheme={statusColors[k]}>{t([k, statusTexts[k]])}</Badge>
            </Radio>
          ))}
        </Stack>
        <FormHelperText><Trans t={t} i18nKey="userStatusHelpTxt">Kasutaja saab logida sisse ainult siis kui olek on <Badge fontSize="0.8em" variant="subtle" colorScheme="green">SEES</Badge></Trans></FormHelperText>
      </FormControl>
      <Flex mt={3}>
        <FormControl flex={1}>
          <FormLabel>{t(["lastSignInField", "Viimati logis sisse kell"])}</FormLabel>
          <Text>{kp}</Text>
        </FormControl>
        <FormControl flex={1}>
          <FormLabel as="legend">{t(["lastSignInIPField", "Viimane IP"])}</FormLabel>
          <Text>{user.last_sign_in_ip || '--'}</Text>
        </FormControl>
      </Flex>
      <ChangesRow updatedAt={user.updated_at} createdAt={user.created_at} />
      <FormControl as="fieldset" isRequired mt={3}>
        <FormLabel htmlFor="type">{t(["providerNameField", "Kuulub grupile"])}</FormLabel>
        <MultiSelect
          name="service_provider_id"
          options={Object.keys(providers).map((s) => (String(s)))}
          getOptionLabel={(o) => { return (providers[String(o)] || {}).name }}
          getOptionValue={(o) => { return String(o)}}
          value={user.service_provider_id ? [String(user.service_provider_id)]: []}
          onChange={(sp) => {
            handleChanges('service_provider_id')(sp);
          }}
          placeholder={t(["providerNamePlaceholderTxt","Vali grupp"])}
        />
        <FormHelperText><T k="providerNameHelpTxt">Grupp määrab kellele kasutaja kuulub ja see omakorda määrab kellel on õigus kasutaja hallata</T></FormHelperText>
      </FormControl>

      <FormControl as="fieldset" isRequired mt={3}>
        <FormLabel htmlFor="type">{t(["mayManageField", "Halda gruppe"])}</FormLabel>
        <MultiSelect
          isMulti
          name="service_provider_id"
          options={Object.keys(providers).map((s) => (String(s)))}
          getOptionLabel={(o) => { return (providers[String(o)] || {}).name }}
          getOptionValue={(o) => { return String(o)}}
          value={user.service_provider_ids ? user.service_provider_ids.map((o) => String(o)) : []}
          onChange={(sp) => {
            const prov = (sp || []).map((s) => Number(s))
            handleChanges('service_provider_ids')(prov)
          }}
          placeholder={t(["mayManagePlaceholderTxt","Vali grupid mida kasutaja võib hallata"])}
        />
      </FormControl>
      <FormControl mt={3}>
        <FormLabel htmlFor="name">{t(["nameField", "Nimi"])}</FormLabel>
        <Input
          type="text"
          name="name"
          value={user.name || ""}
          onChange={handleChanges()}
          placeholder={t(["namePlaceholderTxt","Sisesta kasutaja päris nimi"])}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3}>
        <FormLabel htmlFor="email">{t(["emailField", "E-post"])}</FormLabel>
        <Input
          type="text"
          name="email"
          value={user.email || ""}
          onChange={handleChanges()}
          placeholder={t(["emailPlaceholderTxt","E-post kasutatakse sisselogimiseks"])}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <Stack isInline mt={3} spacing={3}>
        <FormControl flex={1}>
          <FormLabel htmlFor="password">{t(["passwordField", "Salasõna"])}</FormLabel>
          <Input
            type="text"
            name="password"
            value={user.password || ''}
            onChange={handleChanges()}
            placeholder={t(["passwordPlaceholderTxt","Täida lahter selleks, et vahetada salasõna"])}
          />
        </FormControl>
      </Stack>
      <ButtonActionRow onDelete={(ev) => { handleDelete(ev); history.replace({ pathname: '/users'  }); }} onResetForm={handleReset} state={state} />
    </Box>
  </Box>
}

export default UserDetails;

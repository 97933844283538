import React, { Suspense } from "react"
import ReactDOM from 'react-dom';
import {
  RecoilRoot,
} from 'recoil';
import {ChakraProvider } from "@chakra-ui/react"
import Fonts from "./fonts"
import theme from "./theme"
import Routes from "./routes";
import './i18n';

function App() {

  console.log(AC_API_URL);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <RecoilRoot>
        <Routes/>
      </RecoilRoot>
    </ChakraProvider>
  )
}

ReactDOM.createRoot(document.getElementById('noblessner')).render(<App/>)

import React from "react";
import { Flex, FormControl, FormLabel, Text} from "@chakra-ui/react";
import {format} from "date-fns";
import T, {useT} from "./t";

function tPb2Str(d, f) {
  if(!d) {
    return "--";
  }

  if(!f) {
    f = 'dd.MM.yyyy \'kell\' kk:mm'
  }

  if(Object.prototype.toString.call(d) === "[object String]") {
    return format(
      new Date(d),
      f
    )
  }

  const { seconds, nanos } = d;
  return format(
    new Date((seconds * 1000) + (nanos / 1000000)),
    f
  )
}

const ChangesRow = ({ updatedAt, createdAt }) => {

  const { t } = useT();
  const f = t('dd.MM.yyyy \'kell\' kk:mm:ss', { keySeparator: '*', nsSeparator: '%' });

  return       <Flex mt={3}>
    <FormControl flex={1}>
      <FormLabel><T k="updatedAtField">Viimati muudetud kp</T></FormLabel>
      <Text>{tPb2Str(updatedAt, f)}</Text>
    </FormControl>
    <FormControl flex={1}>
      <FormLabel><T k="createdAtField">Loodud kp</T></FormLabel>
      <Text>{tPb2Str(createdAt, f)}</Text>
    </FormControl>
  </Flex>
}

export default ChangesRow

import * as React from 'react';
import {useCallback} from "react";

import { useToast } from "@chakra-ui/react"

import { useRecoilState, useRecoilValue } from "recoil";
import {
  AppStore,
  ListResourceQueryResult,
} from "./store";

export function useList(resourcePath) {

  const [list, setList] = useRecoilState(ListResourceQueryResult(resourcePath))
  const [appState, setAppState] = useRecoilState(AppStore)

  const handleListQuery = useCallback((params) => {

    return (async (params) => {

      const res = await fetch(`${AC_API_URL}${resourcePath}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token
        },
        body: JSON.stringify(params)
      })
      const resp = await res.json()

      if(res.status == 401) {
        setAppState({ ...appState, token: null })
      }

      if (resp.success) {
        setList(resp)
      }
    })(params);

  }, [resourcePath])




  return { list, handleListQuery }

}

import React, {useTransition, useEffect} from 'react';

import {Badge, Button, Flex, Heading} from "@chakra-ui/react";
import Table from "./table";
import SearchWidget from "./SearchWidget";
import {format} from "date-fns";
import {useList} from "./useList";
import {useSearch} from "./useSearch";
import {useHistory} from "react-router-dom";
import T, {useT} from "./t";

const statusColors = {
  'ACCEPT': 'green',
  'DENY': 'orange',
  'ERROR': 'red'
}

const statusTexts = {
  'ACCEPT': 'LUBATUD',
  'DENY': 'KEELATUD',
  'ERROR': 'VIGA'
}

const LogList = () => {

  const { t } = useT();

  let history = useHistory();
  const { list: logs, handleListQuery } = useList('/accesslog/list')
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/accesslog/list', handleListQuery)


  const onRowClick = ({ id }) => {
    history.push(`/logs/${id}`)
  }

  console.log('LogList', ' logs: ', logs);


  return <Flex flexDirection="column" flex="1" pr={4}>
    <Heading mb={2}><T k="logsHeading">Logi</T></Heading>
    <SearchWidget query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                  clearSearch={clearSearch}/>

    <Table
      columns={[
        {
          Header: t(["resultColumn", "Tulemus"]),
          id: "result",
          accessor: (r) => {
            return <Badge fontSize="0.8em" variant="subtle" colorScheme={statusColors[r.result]}>{t(r.result, statusTexts[r.result])}</Badge>
          }
        },{
          Header: t(["codeColumn", "Kood"]),
          accessor: "code"
        },{
          Header: t(["timeColumn", "Aeg"]),
          id: "created_at",
          accessor: (r) => {
            return format(new Date(r.created_at), 'dd.MM.yyyy \'at\' kk:mm');
          }
        },{
          Header: t(["zoneColumn", "Tsoon"]),
          accessor: "site_name"
        },{
          Header: t(["lockColumn", "Lukk"]),
          accessor: "controller_name"
        },
      ]}
      data={logs.data}
      totalHits={logs.totalHits}
      page={logs.page}
      lastPage={logs.totalHits >= 0 ? logs.lastPage + 1: 0}
      pageSize={logs.pageSize}
      sortBy={""}
      sortDesc={false}
      fetchData={(p) => { handleListQuery(p) } }
      onRowClick={onRowClick}
    />
  </Flex>
}

export default LogList;

import React, {useTransition, Suspense, useEffect, useCallback} from 'react';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue, atom, selector,
} from 'recoil';
import {Badge, Box, Button, Flex, Heading, SimpleGrid, Text, useToast} from "@chakra-ui/react";
import {AppStore, ListQueryState, ListResourceQuery} from "./store";
import { useHistory, Link } from "react-router-dom";
import SearchWidget from "./SearchWidget";
import {useList} from "./useList";
import {useSearch} from "./useSearch";
import {format, differenceInSeconds} from "date-fns";
import T, {useT} from "./t";


const queryRequestID = atom({
  key: 'openDoorQueryRequest',
  default: {
    doorId: null,
    date: new Date()
  },
});

/*
const openDoor = selector({
  key: "openDoorQuery",
  get: async ({get}) => {

    const reqData = get(queryRequestID);
    if(!reqData.doorId) {
      return null
    }

    const appState = get(AppStore)

    const res = await fetch(`${AC_API_URL}/open/${reqData.doorId}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + appState.token
      }
    })
    const resp = await res.json()

    // API error message
    if (!resp.success) {
      throw resp.message;
    }

    return resp
  }
})
*/

const UpdatedAtStore = atom({
  key: 'UpdatedAtStore',
  default: new Date(),
});

const UpdateStore = atom({
  key: 'UpdateStore',
  default: true,
});

const statusColors = {
  'success': 'green',
  'warning': 'orange',
  'error': 'red'
}

const statusTexts = {
  'success': 'Korras',
  'warning': 'Korras',
  'error': 'Viga'
}

function LastOnlineStatus(ttl, last_online_at) {

  if(!last_online_at) {
    return "error"
  }

  // If we saw the device less than ttl*2 - then green
  if(differenceInSeconds(new Date(), new Date(last_online_at)) < (ttl*2)) {
    return "success"
  } else
    // If we saw the device less than ttl*4 - then yellow
  if(differenceInSeconds(new Date(), new Date(last_online_at)) < (ttl*4)) {
    return "warning"
  }

  // Else the device is not online
  return "error"
}

const ControllerList = () => {

  const { t } = useT();

  let history = useHistory();
  const toast = useToast();
  let appState = useRecoilValue(AppStore)
  const { list: controllers, handleListQuery } = useList('/controller/list')
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/controller/list', handleListQuery)

  const [update, setUpdate] = useRecoilState(UpdateStore);
  const [updatedAt, setUpdatedAt] = useRecoilState(UpdatedAtStore);
  const listUpdatedAtF = format(
    updatedAt,
    t('dd.MM.yyyy \'kell\' kk:mm:ss', { keySeparator: '*', nsSeparator: '%' })
  );

  const [isPending, startTransition] = useTransition({
    timeoutMs: 3000
  });

  const openDoor = (doorID, channelID) => {
    (async () => {
      const res = await fetch(`${AC_API_URL}/open/${doorID}` + (typeof channelID === 'number' ? `/${channelID}`:'/0'), {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store',
          'Authorization': 'Bearer ' + appState.token
        }
      })
      const resp = await res.json()
      if(res.status != 200 || !resp.success ) {
        toast({
          title: t(["errorOpeningLock", "Viga avamisel!"]),
          description: resp.message || resp.error,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        })
      } else {
        toast({
          title: t(["successOpeningLock", "Uks avatud!"]),
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        })
      }
    })();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if(update) {
        setUpdatedAt(new Date())
        handleListQuery();
      }
    }, 10000);
    handleListQuery();
    return () => clearInterval(interval);
  }, [update])

  console.log('ControllerList', ' controllers: ', updatedAt);

  return <Flex flexDirection="column" flex="1" pr={4}>
    <Heading mb={2}><T k="dashboardHeading">Lukkude Olek</T></Heading>
    <SearchWidget query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                  clearSearch={clearSearch}/>

    <Flex mb={4}>
      <Flex flex="1" justifyContent="flex-start">
        <Text><T k="queryDoneAt">Kontrollitud</T>:&nbsp;</Text>
        <Text>{listUpdatedAtF}</Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
      <Button colorScheme="blue" variant="outline" onClick={() => { setUpdate(!update) }}>
        {update ? t(["stopStatusQuery","Peata kontrolllimine"]) : t(["startStatusQuery", "Alusta kontrollimine"])}
      </Button>
      </Flex>
    </Flex>

    <SimpleGrid minChildWidth="310px" spacing="20px">
      {controllers.data.map((c)=> {
        const status = LastOnlineStatus(180, c.updated_at)

        //
        // Open door button(s)
        //
        let openButtonz = [<Button key="default" width="100%" onClick={() => {
          openDoor(c.id)
          /*
          startTransition(() => {
            setDoorQry({
              doorId: c.id,
              date: new Date()
            })
          })
           */
        }}><T k="openLockBtnTxt">Ava uks</T></Button>];

        if(Object.keys(c.channels || {}).length > 0) {
          openButtonz = Object.keys(c.channels).map((b) => {
            return <Button mt={2} key={b} width="100%" onClick={() => { openDoor(c.id, c.channels[b])}}><T k="openLockChannelBtnTxt">Ava</T> {b}</Button>
          })
        }

        //
        // Card
        //
        return <Flex flexDirection="column" key={c.id} border="1px solid" borderColor={((String(status).toUpperCase() == 'SUCCESS' && (c.lock_state && c.lock_state.state === false)) ? 'green.200' : 'gray.200')} p={2} borderRadius="3px">
        <Heading size="md">{c.name}</Heading>
        <Flex justifyContent="space-between" pt={2}>
          <Badge variant="subtle" colorScheme={statusColors[status]}>{t([`ONLINE_${String(status).toUpperCase()}`,statusTexts[status]])}</Badge>
          <Badge variant="subtle" colorScheme={c.use_lock_state ? (c.lock_state && c.lock_state.state === true ? 'red' : 'green') : "gray"} >{c.use_lock_state ? t((c.lock_state && c.lock_state.state === true) ? 'OPEN_LOCK_STATE' : 'CLOSED_LOCK_STATE') : t("UNKNOWN_LOCK_STATE")}</Badge>
        </Flex>
        <Box flex="1" m={2}><T k="lockLastSeenAt">Viimati nähtud</T>: {c.updated_at ? format(
          new Date(c.updated_at),
          t('dd.MM.yyyy \'kell\' kk:mm', { keySeparator: '*', nsSeparator: '%' })
          ) : '--'}</Box>
        <Suspense fallback={t("pleaseWaitSuspense","Palun oota")}>
          {openButtonz}
        </Suspense>
      </Flex>})}
    </SimpleGrid>

  </Flex>
}

/*
created_at: "2020-11-10T12:30:09.392459+00:00"
id: 69
last_seen: "2020-11-12T11:59:32+00:00"
name: "EMT Test"
online_since: "2020-11-12T11:58:54+00:00"
static_ip: "37.157.74.89"
status: "ENABLED"
updated_at: null

*/

export default ControllerList;

import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
  fonts: {
    heading: AC_PROVIDER_SITE == 'maxattefall' ? "Lato" :"GothamPro",
    body: AC_PROVIDER_SITE == 'maxattefall' ? "Lato" : "GothamPro",
  },
  styles: {
    global: {
      h2: {
        textTransform: "uppercase"
      },
      ul: {
        padding: '10px'
      },
      li: {
        listStyleType: 'none'
      }
    }
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "4px"
      }
    },
    Form: {
      baseStyle: {
        helperText: {
          lineHeight: '1.3em'
        }
      }
    }
  },
  radii: {
    md: "2px"
  }
})
export default theme

import React, { useTransition } from 'react';
import {Badge, Button, Flex, Heading} from "@chakra-ui/react";
import Table from "../../table";

import { useHistory, Link } from "react-router-dom";
import SearchWidget from "../../SearchWidget";
import {useList} from "../../useList";
import {useSearch} from "../../useSearch";
import T, {useT} from "../../t.js";

const typeColors = {
  'FIRMWARE': 'blue',
  'CONFIG': 'green'
}

const typeTexts = {
  'FIRMWARE': 'FIRMWARE',
  'CONFIG': 'CONFIG'
}

const TerminalList = () => {

  const { t } = useT();

  let history = useHistory();
  const { list: controllers, handleListQuery } = useList('/controllers/list')
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch('/controllers/list', handleListQuery)

  const onRowClick = ({ id }) => {
    history.push(`/ota/${id}`)
  }

  console.log('ControllerList', controllers);

  return <Flex flexDirection="column" flex="1" pr={4}>
    <Heading mb={2}><T k="controllersHeading">Terminaalid</T></Heading>
    <SearchWidget query={query} handleSearch={handleSearch} handleSearchEnter={handleSearchEnter}
                  clearSearch={clearSearch}/>
    <Table
      columns={[{
        Header: t(["updateTypeColumn", "Tüüp"]),
        id: "update_type",
        accessor: (r) => {
          return <Badge fontSize="0.8em" variant="subtle" colorScheme={typeColors[r.update_type]}>{typeTexts[r.update_type]}</Badge>
        }
      },{
        Header: t(["versionColumn", "Versioon"]),
        accessor: "version"
      },{
        Header: t(["descriptionColumn", "Kirjeldus"]),
        accessor: "description"
      }
      ]}
      data={controllers.data}
      totalHits={controllers.totalHits}
      page={controllers.page}
      lastPage={controllers.totalHits >= 0 ? controllers.lastPage + 1: 0}
      pageSize={controllers.pageSize}
      sortBy={""}
      sortDesc={false}
      fetchData={(p) => {
        handleListQuery(p)
      }}
      onRowClick={onRowClick}
    />
  </Flex>
}

export default TerminalList;

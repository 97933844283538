import React, {useState} from 'react';
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  CircularProgress,
  Text
} from "@chakra-ui/react";
import ErrorMessage from "./ErrorMessage";
import {AppStore} from "./store";
import {useT} from "./t.js";

const logUserIn = async (data) => {
  const res = await fetch(`${AC_API_URL}/login`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return await res.json()
}

const Login = () => {

  const { i18n } = useT();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [appState, setAppState] = useRecoilState(AppStore)

  const isLoggedIn = !!appState.token
  const error = null

  const logUserOut = () => {
    setAppState({ ...appState, token: null })
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    logUserIn({
      sub: email,
      pass: password
    }).then((r) => {

      if(r.success && r.token) {
        setAppState({ ...appState, token: r.token, logo: r.logo, languages: r.languages, language: appState.language || r.languages[0] })
      }
      if(!appState.language) {
        i18n.changeLanguage(r.languages[0])
      }
      setIsLoading(false);
      console.log('Login result', r)
    }).catch(() => {
      setIsLoading(false);
    })
    console.log(email, password)

  }

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box
        p={8}
        maxWidth="500px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
      >
        {isLoggedIn ? (
          <Box textAlign="center">
            <Text>{email} logged in!</Text>
            <Button
              colorScheme="orange"
              variant="outline"
              width="full"
              mt={4}
              onClick={() => logUserOut(false)}
            >
              Sign out
            </Button>
          </Box>
        ) : (
          <>
            <Box textAlign="center">
              <Heading>Login</Heading>
            </Box>
            <Box my={4} textAlign="left">
              <form onSubmit={handleSubmit}>
                {error && <ErrorMessage message={error} />}
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    placeholder="test@test.com"
                    size="lg"
                    onChange={event => setEmail(event.currentTarget.value)}
                  />
                </FormControl>
                <FormControl isRequired mt={6}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="*******"
                    size="lg"
                    onChange={event => setPassword(event.currentTarget.value)}
                  />
                </FormControl>
                <Button
                  colorScheme="teal"
                  variant="outline"
                  type="submit"
                  width="full"
                  mt={4}
                >
                  {isLoading ? (
                    <CircularProgress
                      isIndeterminate
                      size="24px"
                      color="teal"
                    />
                  ) : (
                    'Sign In'
                  )}
                </Button>
              </form>
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
}

export default Login

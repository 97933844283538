import * as React from 'react';
import {useCallback, useEffect} from "react";

import { useToast } from "@chakra-ui/react"

import {useRecoilCallback, useRecoilState, useSetRecoilState} from "recoil";
import {
  AppStore,
  CreateResourceQuery,
  DeleteResourceQuery,
  ResourceDataStore,
  ResourceState,
  UpdateResourceQuery
} from "./store";
import {useHistory} from "react-router-dom";

export function useResource(resourcePath) {

  const [appState, setAppState] = useRecoilState(AppStore)
  const [state, setState] = useRecoilState(ResourceState(resourcePath))
  const [storeData, setStoreData] = useRecoilState(ResourceDataStore(resourcePath))

  const history = useHistory()

  const toast = useToast()

  useEffect(() => {

    (async () => {

      //const req_res = resourcePath.split('/').filter(e => e)[0]
      //const req_id = get(queryRequestID(req_res));

      const res = await fetch(`${AC_API_URL}${resourcePath}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + appState.token
        },
      })
      const resp = await res.json()

      if(res.status == 401) {
        setAppState({ ...appState, token: null })
      }

      if(resp.success) {
        setStoreData({...storeData, data: resp.data, related: resp.related})
      }

    })()

  }, [resourcePath])


  const handleChanges = useCallback((fieldName) => {
    return (evOrValue) => {
      if(!evOrValue.target && fieldName) {
        console.log('--- we are a value --')
        setState({ [fieldName]: evOrValue })
      } else if(evOrValue.target) {
        const target = evOrValue.target;
        if(fieldName) {
          setState({ [fieldName]: target.value })
        } else if (target.name) {
          setState({ [target.name]: target.value })
        }
      }
    }
  },[resourcePath])

  const handleReset = useCallback(() => {
    console.log('handleReset')
    setState({})
  },[resourcePath])

  const handleSave = useRecoilCallback(({ snapshot }) => async () => {

    let result = null;

    // Handle errors
    try {
      //If no ID then create
      if(state.data.id === null) {
        result = await snapshot.getPromise(CreateResourceQuery(resourcePath));
      } else {
        result = await snapshot.getPromise(UpdateResourceQuery(resourcePath));
      }
      setStoreData(result)
      toast({
        title: "Salvestatud!",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      toast({
        title: "Viga salvestamisel!",
        description: e,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      })
    }
  }, [resourcePath, storeData]);

  const handleDelete = useRecoilCallback(({ snapshot }) => async () => {
    const result = await snapshot.getPromise(DeleteResourceQuery(resourcePath));
    console.log('---- handleDelete ----', result)
  },[resourcePath]);

  return { state, handleChanges, handleReset, handleSave, handleDelete }
}

import React, {Suspense, useEffect} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {AppStore} from "./store";
import {Flex, List, ListItem} from "@chakra-ui/react";
import styled from '@emotion/styled'
import {BrowserRouter as Router, Link, NavLink, Route, Switch} from "react-router-dom";
import T, { useT } from "./t";
import CodeDetails from "./codedetails";
import CodeList from "./codelist";
import LogDetails from "./LogDetails";
import LogList from "./LogList";
import UserList from "./UserList";
import Login from "./Login";
import ControllerList from "./ControllerList";
import UserDetails from "./UserDetails";
import Logout from "./Logout";
import N from "./n.svg"
import OtaList from "./views/ota/OtaList.js";
import {Terminal} from "react-feather";
import TerminalList from "./views/controllers/ControllerList.js";

const Logo = styled(N)`
  height: 100px;
  width: 100%;
`;

const MaxLogo = styled('img')`
  height: 100px;
  width: 70%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
`

const LngList = styled('div')`
  display: flex;
  justify-content: center;
`

const LngItem = styled('a')`
  margin-right: 6px;
  &:not(:last-child):after {
    padding-left: 6px;
    content: "|";
    font-weight: 100;
  }
  cursor: pointer;
`

const Routes = () => {

  const { t, i18n } = useT();

  const [appState, setAppState] = useRecoilState(AppStore)

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAppState({ ...appState, language: lng })
  };

  if(!appState.token) {
    return <Router>
      <Flex minH="100vh" flexDir="row">
        <Login/>
      </Flex>
    </Router>
  }

  return <Router>
    <Flex minH="100vh" flexDir={{ base: 'column', md: 'row' }}>
      <Flex width="300px" flexDir="column" justifyContent="flex-start" paddingTop="60px">
        <LngList>
        {(appState.languages || []).map( (l) => <LngItem key={l} style={{ fontWeight: (i18n.language == l ? '800' : '100') }} onClick={() => changeLanguage(l)}>{l}</LngItem>)}
        </LngList>
        {appState.logo == '' && <Logo/>}
        {appState.logo != '' && <MaxLogo src={appState.logo} />}
        <List spacing={3} p={6} mt="30px">
          <ListItem fontSize="20px" color="#e5123e">
            <NavLink activeStyle={{
              color: "#000"
            }} exact to="/"><T k="dashboardMenuItem">Olek</T></NavLink>
          </ListItem>
          {Object.entries({
            "/codes": ["codesMenuItem", "Koodid"],
            "/logs": ["logMenuItem", "Logi"],
            "/users": ["usersMenuItem", "Kasutajad"]
            /*
            ,
            "/controllers": ["controllerMenuItem", "Terminaalid"],
            "/ota": ["otaMenuItem", "Versioonid"]
             */
          }).map(([k, v]) => <ListItem fontSize="20px" color="#e5123e" key={k}>
            <NavLink activeStyle={{
              color: "#000"
            }} to={k}>{t(v)}</NavLink>
          </ListItem>)}
          <ListItem paddingTop="40px">
            <Link to="/logout"><T k="logoutMenuItem">Lahku</T> ➝</Link>
          </ListItem>
        </List>

      </Flex>
      <Flex as="section" flex="1" paddingTop={6} paddingRight={4}>
        <Suspense fallback="Loading...">
          <Switch>
            <Route path="/logout">
              <Logout />
            </Route>
            <Route path="/codes/:id">
              <CodeDetails/>
            </Route>
            <Route path="/codes">
              <CodeList />
            </Route>
            <Route path="/logs/:id">
              <LogDetails/>
            </Route>
            <Route path="/logs">
              <LogList/>
            </Route>
            <Route path="/users/:id">
              <UserDetails/>
            </Route>
            <Route path="/users">
              <UserList/>
            </Route>
            {/*
            <Route path="/controllers">
              <TerminalList />
            </Route>
            <Route path="/ota">
              <OtaList />
            </Route>
            */}
            <Route path="/login">
              <Login/>
            </Route>
            <Route path="/">
              <ControllerList/>
            </Route>
          </Switch>
        </Suspense>
      </Flex>
    </Flex>
  </Router>

};

export default Routes
